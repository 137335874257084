<!-- 培训班级列表 -->
<template>
    <div class="ClassList">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" label-width="70px">
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                    <el-option v-for="item in form.stateChose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="班类型">
                <el-select v-model="form.classType" placeholder="请选择班类型" :clearable="true">
                    <el-option v-for="item in form.typeChose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="" style="margin-left: 25px;margin-top: 5px;margin-bottom: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="reset">
                    重置
                </el-button>
            </div>
        </el-form>
       <div class="" style="margin-left: 21px;">
           <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
               @click="refresh">
               <!-- 刷新 -->
           </el-button>
           <el-button size="small" icon="el-icon-plus" 
           style="background: #18BC9C;color: #FFFFFF;" @click="newlyAdded">
               新增
           </el-button>
           <!-- <button class="print">打印预览</button> -->
       </div>
       <!-- 表格 -->
       <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" @sort-change="abc">
           <!-- 复选按钮 -->
           <el-table-column type="selection" align="center" width="50">
           </el-table-column>
           <el-table-column type="index" label="序号" align="center" width="80">
           </el-table-column>
           <el-table-column prop="class_status" label="状态" sortable="custom" align="center" width="100">
               <template slot-scope="scope">
                   <span v-if="scope.row.class_status==1">开班</span>
                   <span v-else-if="scope.row.class_status==2">取消</span>
                   <span v-else-if="scope.row.class_status==3">结业</span>
               </template>
           </el-table-column>
           <el-table-column prop="number" label="编码" sortable="custom" align="center" width="120">
           </el-table-column>
           <el-table-column prop="class_type" label="班类型" sortable="custom" align="center" width="150">
               <template slot-scope="scope">
                   <span v-if="scope.row.class_type==1">春季</span>
                   <span v-else-if="scope.row.class_type==2">秋季</span>
                   <span v-else-if="scope.row.class_type==3">寒假</span>
                   <span v-else-if="scope.row.class_type==4">暑假</span>
                   <span v-else-if="scope.row.class_type==5">考前培训</span>
               </template>
           </el-table-column>
           <el-table-column prop="class_name" label="班级名称" sortable="custom" align="center" width="300">
           </el-table-column>
           <el-table-column prop="resources" label="授课教师" sortable="custom" align="center" width="120">
           </el-table-column>
           <el-table-column prop="course" label="学科" sortable="custom" align="center" width="150">
           </el-table-column>
           <el-table-column prop="tuition_incidentals" sortable="custom" label="学杂费/人" align="center" width="150">
           </el-table-column>
           <el-table-column prop="tuition" label="学费" sortable="custom" align="center" width="150">
           </el-table-column>
           <el-table-column prop="planned_size" sortable="custom" label="计划招生数" align="center" width="150">
           </el-table-column>
           <el-table-column prop="actual_size" sortable="custom" label="实际学员数" align="center" width="150">
           </el-table-column>
           <el-table-column prop="start_time" sortable="custom" label="开班日期" align="center" width="200">
           </el-table-column>
           <el-table-column prop="end_time" sortable="custom" label="结业日期" align="center" width="200">
           </el-table-column>
           <el-table-column prop="lecture_number" sortable="custom" label="授课次数" align="center" width="200">
           </el-table-column>
           <el-table-column prop="classroom" sortable="custom" label="上课教室" align="center" width="200">
           </el-table-column>
           <el-table-column prop="class_arrangement" sortable="custom" label="上课安排" align="center" width="200">
           </el-table-column>
           <el-table-column prop="add_name" sortable="custom" label="录入人" align="center" width="120">
           </el-table-column>
           <el-table-column prop="add_time" sortable="custom" label="录入时间" align="center" width="200">
           </el-table-column>
           <el-table-column prop="update_name" sortable="custom" label="修改人" align="center" width="120">
           </el-table-column>
           <el-table-column prop="update_time" sortable="custom" label="最近调整时间" align="center" width="200">
           </el-table-column>
           <el-table-column  label="操作" align="center" fixed="right" width="280">
               <template slot-scope="scope">
                   <el-button class="stu" size="mini" @click="StudentInfo(scope.$index, scope.row)">
                       学员
                   </el-button>
                   <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;" 
                    @click="edit(scope.$index, scope.row)">
                       编辑
                   </el-button>
                   <el-button type="danger" icon="el-icon-delete" size="mini" @click="delt(scope.$index, scope.row)">
                       删除
                   </el-button>
               </template>
           </el-table-column>
       </el-table>
       <!--分页 -->
       <el-pagination @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage"
           :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="10" background
           layout="total, sizes, prev, pager, next, jumper" :total="total" >
       </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   search: "", //搜索
                   condition:0,//状态
                   stateChose:[
                       {value: 0,label: '全部'},
                       {value: 1,label: '开班'},
                       {value: 2,label: '取消'},
                       {value: 3,label: '结业'}
                   ],//状态选择
                   classType:0,//班类型
                   typeChose:[
                       {value: 0,label: '全部'},
                       {value: 1,label: '春季'},
                       {value: 2,label: '秋季'},
                       {value: 3,label: '寒假'},
                       {value: 4,label: '暑假'},
                       {value: 5,label: '考前培训'}
                   ]//班类型选项
                },
                tableData:[],//表格数据
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                arr:[]
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){//获取列表
                this.$request({
                   url: '/api/class_/list',
                   method: 'POST',
                   data:{
                       page:this.currentPage,
                       limit:this.currentLimit,
                       sort:this.arr
                   } 
                }).then(res=>{
                    console.log(res,'列表')
                    if(res.code==1){
                        this.tableData=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addClassList"
                })
            },
            edit(index,row){//编辑
                this.$router.push({
                    path: "editClassList",
                    query:{
                        id:row.id
                    }
                })
            },
            StudentInfo(index,row){//学员信息
                this.$router.push({
                    path: "studentInfo",
                    query:{
                        id:row.id
                    }
                })
            },
            delt(index,row){//删除
                this.$confirm('此操作将永久删除该数据，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    acncelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url:'/api/class_/del',
                        method:'POST',
                        data:{
                            id: row.id
                        },
                    }).then(res=>{
                        if(res.code==1){
                            this.$message({
                              message: res.msg,
                              type: 'success'
                            });
                            this.tableData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            query(){//查询
                this.currentPage=1
                this.getList()
            },
            reset(){//重置
                this.form.search=''//搜索
                this.form.condition=0//状态
                this.form.classType=0//班类型
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
        
    }
</script>

<style scoped="scoped">
    .ClassList{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .form {
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
    }
    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    /* 新增按钮 */
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 打印预览 */
    .print{
        display: inline-block;
        padding-left: 10px;
        width: 94px;
        height: 30px;
        border-radius: 3px;
        vertical-align: middle;
        background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
        border: none;
        color: #FFFFFF;
        margin:0px 10px;
        cursor: pointer;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    .stu{
        display: inline-block;
        padding-left: 35px;
        background: url(../../assets/stud.png)10px 7px no-repeat #FF7300;
        color: #FFFFFF;
        font-size: 12px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
